<template>
  <div data-app>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-card>
          <v-form ref="form5" v-model="valid" lazy-validation class="pt-4">
            <v-container grid-list-md>
              <v-layout row wrap>
                <div class="container2">
                  <div class="titulo">
                    <h1>Tarjeta de crédito o débito</h1>
                  </div>
                </div>
                <div class="tarjetacredito">
                  <h1>Tarjetas de crédito</h1>
                </div>
                <div class="imagencredito">
                  <img src="img/cards1.png" />
                </div>
                <div class="tarjetadebito">
                  <h1>Tarjetas de débito</h1>
                </div>
                <div class="imagendebito">
                  <img src="img/cards2.png" />
                </div>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fa-id-card"
                    label="Nombre del titular"
                    v-model="name_titular"
                    type="text"
                    required
                    :rules="[
                      (v) => !!v || 'El nombre del titular es requerido',
                    ]"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fa-id-card"
                    label="Apellidos del titular"
                    v-model="last_name_titular"
                    type="text"
                    required
                    :rules="[
                      (v) => !!v || 'Los apellidos del titular son requeridos',
                    ]"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fa-envelope"
                    name="email"
                    label="Correo electrónico"
                    type="email"
                    v-model="email"
                    required
                    autocomplete="off"
                    :rules="emailRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fa-envelope"
                    name="telefono"
                    label="Teléfono"
                    type="number"
                    v-model="telefono"
                    required
                    :rules="[
                      (v) =>
                        v.length === 10 || 'Se requieren 10 dígitos',
                    ]"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fa-credit-card"
                    name="number_card"
                    label="Número de tarjeta"
                    type="text"
                    v-model="formattedNumber"
                    required
                    :rules="[
                      (v) => !!v || 'El número de tarjeta es requerido',
                      (v) =>
                        v.length >= 19 || 'Se requieren al menos 16 dígitos',
                    ]"
                    autocomplete="off"
                    maxlength="19"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs5>
                  <v-text-field
                    prepend-icon="fa-credit-card-alt"
                    name="cvc"
                    label="CVC"
                    type="number"
                    v-model="cvv2"
                    :rules="[rules_cvc.required, rules_cvc.min, rules_cvc.max]"
                    required
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <div class="imagen">
                  <img src="img/cvv.png" />
                </div>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm12 md6 lg6 xl6>
                  <v-select
                    prepend-icon="fa-calendar-o"
                    :items="items_months"
                    v-model="month_expiration"
                    label="Mes de expiración"
                    required
                    :rules="[(v) => !!v || 'El mes de expiración es requerido']"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md6 lg6 xl6>
                  <v-text-field
                    maxlength="2"
                    prepend-icon="fa-calendar-o"
                    label="Año de expiración"
                    v-model="year_expiration"
                    :rules="rules_year"
                    type="number"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <div class="info">
                <p>Transacciones realizadas vía:</p>

                <div class="imagen3">
                  <img src="img/open.png" />
                </div>
                <v-row>
                  <div class="segura">
                    <p>
                      Tus pagos se realizan de forma segura encriptación de 256
                      bits
                    </p>
                  </div>
                  <div class="imagen4">
                    <img src="img/security.png" />
                  </div>
                </v-row>
              </div>
              <v-layout align-center justify-center column class="pt-2">
                <div class="alert alert-error" role="alert" v-if="msg_error">
                  {{ error_msg }}
                </div>
                <div class="alert alert-primary" role="alert" v-if="msg">
                  {{ error_msg }}
                </div>
                <div class="container">
                  <div v-if="cargando" class="spinner-container">
                    <div class="spinner"></div>
                    <p class="spinner-text">Procesando pago...</p>
                  </div>
                  <button
                    v-if="!cargando"
                    block
                    :style="{ backgroundColor: colorButton }"
                    class="white--text text-capitalize"
                    @click.prevent="pay"
                    :disabled="deshabilitado || formattedNumber.length < 19"
                  >
                    {{ mesageButton }}
                    <v-icon>{{ iconButton }}</v-icon>{{cantidad_pagar}}
                  </button>
                  <button
                    v-if="!cargando && cantidad_pagar === null"
                    block
                    :style="{ backgroundColor: colorButton2 }"
                    class="white--text text-capitalize"
                    @click.prevent="redireccionar"
                  >
                  Seguir comprando
                  <v-icon>{{ iconButton2 }}</v-icon>
                  </button>
                </div>
              </v-layout>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  data: () => ({
    cantidad_pagar: null,
    valid: true,
    number_card: "",
    rules_cvc: {
      required: (v) => !!v || "El CVC es requerido",
      min: (v) => (v && v.length >= 3) || "Mínimo 3 números",
      max: (v) => (v && v.length <= 4) || "Máximo 4 números",
    },
    items_months: [
      { text: "01 - Enero", value: "01" },
      { text: "02 - Febrero", value: "02" },
      { text: "03 - Marzo", value: "03" },
      { text: "04 - Abril", value: "04" },
      { text: "05 - Mayo", value: "05" },
      { text: "06 - Junio", value: "06" },
      { text: "07 - Julio", value: "07" },
      { text: "08 - Agosto", value: "08" },
      { text: "09 - Septiembre", value: "09" },
      { text: "10 - Octubre", value: "10" },
      { text: "11 - Noviembre", value: "11" },
      { text: "12 - Diciembre", value: "12" },
    ],
    deshabilitado: false,
    cargando: false,
    monto: "",
    msg: false,
    msg_error: false,
    error_msg: "",
    name_titular: "",
    email: "",
    token: "",
    telefono: "",
    last_name_titular: "",
    month_expiration: "",
    year_expiration: "",
    transaccion_plataforma_id: "",
    boleto: '',
    cvv2: "",
    rules_year: [
      (v) => !!v || "El año de expiración es requerido",
      (v) => v.length === 2 || "Deben ser máximo 2 números"
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /.+@.+/.test(v) || "El correo electrónico debe de ser valido",
    ],
    endpoint_sandbox_openpay: "https://sandbox-api.openpay.mx/v1/",
    endpoint_payament: "https://api.apoloplatinum.com.mx/api-pasajeros/v1/openpay/pagoConTarjeta",
    deviceSessionId: "",
    // Openpay id y key de Apolo
    openpay_id: "mjkvvzgkqcexblss6tx8",
    openpay_key: "pk_e0ee644c3e2349eaa5ba6c90dbdc5c8a",
    openpay_sandbox_mode: false,
    mesageButton: "Pagar",
    iconButton: "attach_money",
    colorButton: "",
    colorButton2: "blue",
    iconButton2: "home"
  }),
  created(){
    this.boleto = JSON.parse(this.boletos_ida);
  },
  computed: {
    boletos_combinados() {
      const boletos = JSON.parse(this.boletos_ida);
      const boletos_vuelta = JSON.parse(this.boletos_vuelta);
      return boletos.concat(boletos_vuelta);
    },
    formattedNumber: {
      get() {
        return this.formatCardNumber(this.number_card);
      },
      set(newValue) {
        this.number_card = newValue.replace(/-/g, "");
      },
    },
  },

  mounted() {
    // sistema de fraude
    OpenPay.setId(this.openpay_id);
    OpenPay.setApiKey(this.openpay_key);
    OpenPay.setSandboxMode(this.openpay_sandbox_mode);
    this.deviceSessionId = OpenPay.deviceData.setup();

    const sumaCifrada = localStorage.getItem("key");
    const sumaDecifrada = this.decryptData(sumaCifrada);
    const suma = parseFloat(sumaDecifrada);
    this.cantidad_pagar = suma;
  },
  methods: {
    formatCardNumber(number) {
      let formattedNumber = "";
      for (let i = 0; i < number.length; i++) {
        if (i % 4 === 0 && i > 0) {
          formattedNumber += "-";
        }
        if (/^\d$/.test(number[i])) {
          formattedNumber += number[i];
        }
      }
      return formattedNumber;
    },
    decryptData(encryptedData) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
    async pay() {
      const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      const monto = suma;
      this.cargando = true;
      // generate token para realizar el cargo
      OpenPay.token.create(
        {
          holder_name: this.name_titular + " " + this.last_name_titular,
          card_number: this.number_card,
          cvv2: this.cvv2,
          expiration_month: this.month_expiration,
          expiration_year: this.year_expiration,
        },
        (response) => {
          console.log('token');
          console.log(response);
          this.token = response.data.id;
          let json = {
            data: {
              nombre: this.name_titular,
              apellidos: this.last_name_titular,
              email: this.email,
              telefono: this.telefono,
              token_id: this.token,
              monto: monto,
              deviceIdHiddenFieldName: this.deviceSessionId,
            },
          };
          let config = {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
          };
          let me = this;
          axios
            .post("/openpay/pagoConTarjeta", json, config)
            .then(function(response) {
              console.log('pago tarjeta');
              console.log(response);
              if (response.status == 200) {
                me.cargando = false;
                me.mesageButton = "Cargo en Proceso";
                me.deshabilitado = true;
                me.iconButton = "check_circle"; 
                me.colorButton = "green";
                me.transaccion_plataforma_id = response.data.result.transaccion_plataforma_id;
                me.cantidad_pagar = null;
                me.ventaBoletos();
              }
            }).catch(function (error){
              me.cargando = false;
              me.deshabilitado = false;
              me.month_expiration = "";
              me.year_expiration = "";
              me.number_card = "";
              me.cvv2 = "";
              if(error.response.data.errors){
                  me.$notify({
                    group: "apolo",
                    type: "error",
                    title: "Notificación Apolo Platinum",
                    text: error.response.data.errors[0].detail,
                    duration: -1
                  });
              }else{
                me.$notify({
                  group: "apolo",
                  type: "error",
                  title: "Notificación Apolo Platinum",
                  text: error.response.data.message,
                  duration: -1
                });
              }
      });
        }, (onError) => {
          this.cargando = false;
          this.deshabilitado = false;
          this.month_expiration = "";
          this.year_expiration = "";
          this.number_card = "";
          this.cvv2 = "";
          //this.msg_error = true;
          //this.error_msg = onError.data.description;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: onError.data.description,
            duration: -1
          });
        })
      
    },
    redireccionar(){
      this.$router.push('/');
    },
    async ventaBoletos() {
      const value = localStorage.getItem("value");
      if (value == 0) {
        //el viaje es de ida y vuelta
        this.ventaVuelta();
      } else {
        //el viaje es de ida
        this.ventaIda();
      }
    },
    async ventaIda() {
      //console.log("Se inicio venta de boletos SOLO IDA...");
      const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      const monto = suma;
      const boletos = JSON.parse(this.boletos_ida);

      const json2 = {
        data: {
          type: "venta_boleto",
          attributes: {
            token_id: localStorage.getItem("token_id"),
            corrida_ida_id: localStorage.getItem("corrida_ida_id"),
            subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
            ruta_ida_id: localStorage.getItem("ruta_ida_id"),
            fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
            es_redondo: false,
            nombre: localStorage.getItem("nombre"),
            apellido: localStorage.getItem("apellido"),
            email: localStorage.getItem("email"),
            telefono: localStorage.getItem("telefono"),
            enlinea: monto,
            plataforma: 1,
            transaccion_plataforma_id: this.transaccion_plataforma_id,
            asientos: boletos,
            corrida_vuelta_id: null,
            subcorrida_vuelta_id: null,
            ruta_vuelta_id: null,
            fecha_salida_vuelta: null,
          },
        },
      };
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .post("/venta", json2, config)
        .then((data) => {
          if (data.status == "200") {
            this.msg = true;
            this.msg_error = false;
            this.error_msg = "El cargo está siendo procesado, recibirás tu notificación por correo.";
            this.month_expiration = "";
            this.year_expiration = "";
            this.number_card = "";
            this.cvv2 = "";
            localStorage.setItem("venta_exitosa", true);
            //localStorage.clear();
            //console.log("Se hizo con exito LOS BOLETOSSS");
            //console.log(data);
          }
        })
        .catch((error) => {
          localStorage.setItem("venta_exitosa", false);
          this.month_expiration = "";
          this.year_expiration = "";
          this.number_card = "";
          this.cvv2 = "";
         if(error.response.data.errors){
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors[0].detail,
              duration: -1
            });
         }else{
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.message,
            duration: -1
          });
         }
        });
    },
    async ventaVuelta() {
      //console.log("Se inicio venta de boletos  IDA y VUELTA...");
      //console.log(this.boletos_combinados);
      const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      const monto = suma;
      const boletos = JSON.parse(this.boletos_ida);

      if (boletos && Array.isArray(boletos)) {
        for (let i = 0; i < boletos.length; i++) {
          //console.log(boletos);
          //console.log(boletos[i].numero);
        }
      }

      const json2 = {
        data: {
          type: "venta_boleto",
          attributes: {
            token_id: localStorage.getItem("token_id"),
            corrida_ida_id: localStorage.getItem("corrida_ida_id"),
            subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
            ruta_ida_id: localStorage.getItem("ruta_ida_id"),
            fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
            es_redondo: true,
            nombre: localStorage.getItem("nombre"),
            apellido: localStorage.getItem("apellido"),
            email: localStorage.getItem("email"),
            telefono: localStorage.getItem("telefono"),
            enlinea: monto,
            plataforma: 1,
            transaccion_plataforma_id: this.transaccion_plataforma_id,
            asientos: this.boletos_combinados,
            corrida_vuelta_id: localStorage.getItem("corrida_vuelta_id"),
            subcorrida_vuelta_id: localStorage.getItem("subcorrida_vuelta_id"),
            ruta_vuelta_id: localStorage.getItem("ruta_vuelta_id"),
            fecha_salida_vuelta: localStorage.getItem("fecha_vuelta_hora"),
          },
        },
      };
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      //console.log(json2);
      axios
        .post("/venta", json2, config)
        .then((data) => {
          if (data.status == "200") {
            this.msg = true;
            this.msg_error = false;
            this.error_msg = "El cargo está siendo procesado, recibirás tu notificación por correo.";
            this.month_expiration = "";
            this.year_expiration = "";
            this.number_card = "";
            this.cvv2 = "";
            localStorage.setItem("venta_exitosa", true);
            //localStorage.clear();
          }
        })
        .catch((error) => {
          localStorage.setItem("venta_exitosa", false);
          this.month_expiration = "";
          this.year_expiration = "";
          this.number_card = "";
          this.cvv2 = "";
          if(error.response.data.errors){
              this.$notify({
                group: "apolo",
                type: "error",
                title: "Notificación Apolo Platinum",
                text: error.response.data.errors[0].detail,
                duration: -1
              });
          }else{
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.message,
              duration: -1
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.tarjetacredito {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  margin-left: 5%;
  margin-top: 1%;
}
.imagencredito {
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 50%;
}
.tarjetadebito {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  margin-left: 5%;
  margin-top: 3%;
}
.imagendebito {
  margin-top: 4%;
  margin-left: 5%;
}
.container2 {
  margin-top: -2%;
  height: 3em;
  width: 69em;
  background-color: #e52003;
  .titulo {
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    color: white;
    //margin-top: 50px;
  }
}
.info {
  font-family: "Montserrat";
  font-size: 14px;
  margin-right: 10%;
}
.imagen3 {
  height: 100px;
  width: 150px;
  margin-left: 10%;
}

.segura {
  margin-left: 18%;
}
.container button {
  padding: 2px 45px;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  box-shadow: -2px 3px 3px 1px rgba(208, 215, 230, 255);
  font-size: 18px;
  background-color: #e51f04;
  color: rgb(255, 255, 255);
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
}
.container button.selected {
  background-color: #f7fafc;
  border-color: #fff;
}
.spinner-container {
  text-align: center;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-left: 45%;
  margin-top: 0%;
}
.spinner-text {
  margin: 20px 0;
  font-size: 20px;
  color: #333;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.alert {
  padding: 10px;
  margin-bottom: 1px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-error {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}
@media screen and (max-width: 550px) {
  .imagen4 {
    height: 100px;
    width: 150px;
    margin-left: 12%;

    margin-top: -10%;
  }
}
@media screen and (min-width: 551px) and (max-width: 800px) {
  .imagen4 {
    height: 100px;
    width: 150px;
    margin-left: 12%;

    margin-top: -7%;
  }
}
@media screen and (min-width: 801px) and (max-width: 1261px) {
  .imagen4 {
    height: 100px;
    width: 150px;
    margin-left: 12%;

    margin-top: -5%;
  }
}
@media screen and (min-width: 1262px) {
  .imagen4 {
    height: 100px;
    width: 150px;
    margin-left: -45%;

    margin-top: 0%;
  }
}
</style>