<template>
  <div>
    <div v-if="boleto">
      <div
        class="container2"
      >
        <div class="fecha">
          <Subtitulo3 v-bind:subTitulo3="convertirFecha(boleto.fecha_salida)" />
        </div>

        <div class="viaje">
          <Titulo
            v-bind:Titulo="boleto.subcorrida.tramo.terminal_origen.nombre"
          />
        </div>
        <div class="viajeVuelta">
          <Titulo
            v-bind:Titulo="boleto.subcorrida.tramo.terminal_destino.nombre"
          />
        </div>

        <div class="hora">
          <Subtitulo3
            v-bind:subTitulo3="
              boleto.subcorrida.tramo.terminal_origen.abreviatura +
              ' ' +
              new Date(boleto.subcorrida.fecha_salida).toLocaleTimeString(
                'es-ES',
                { hour: '2-digit', minute: '2-digit' }
              ) +
              ' ' +
              (new Date(boleto.subcorrida.fecha_salida).getHours() < 12
                ? 'AM'
                : 'PM') +
              ' - ' +
              boleto.subcorrida.tramo.terminal_destino.abreviatura +
              ' ' +
              new Date(boleto.subcorrida.fecha_llegada).toLocaleTimeString(
                'es-ES',
                { hour: '2-digit', minute: '2-digit' }
              ) +
              ' ' +
              (new Date(boleto.subcorrida.fecha_llegada).getHours() < 12
                ? 'AM'
                : 'PM')
            "
          />
        </div>
        <div class="pasajero">
          <Subtitulo3 subTitulo3="Pasajeros" />
        </div>
        <v-row>
          <div class="nombre">
            <Titulo v-bind:Titulo="boleto.nombre_pasajero" />
          </div>
          <div class="precio">
            <Subtitulo3 v-bind:subTitulo3="'$' + boleto.monto_total_pagar" />
          </div>
          <div class="Facturar">
            <button v-if="validaDiasFacturacion(boleto.subcorrida.fecha_salida, boleto.dias_facturar_despues_salida) === true && boleto.abordado === 1 && boleto.facturado === 0" @click="showAddFactura(boleto)" id="button">
              <App-button :largo="true" buttonText="Facturar" />
            </button>
          </div>
        </v-row>
        <div class="asiento">
          <Subtitulo3 v-if="boleto.asiento != null"
            v-bind:subTitulo3="
              'Asiento ' +
              boleto.asiento +
              ' Categoria ' +
              boleto.categoria_asiento.nombre
            "
          />
          <Subtitulo3 v-else
            v-bind:subTitulo3="
              'Asiento ' +
              'en blanco' +
              ' Categoria ' +
              boleto.categoria_asiento.nombre
            "
          />
        </div>

        <div v-if="boleto.descuento !== null" class="descuento">
          <Subtitulo3 v-bind:subTitulo3="'Descuento ' + boleto.descuento.nombre" />
        </div>

        <div v-if="boleto.facturado === 1"  class="verfactura">
          <button @click="showGetFactura(boleto)" id="button">
            <App-button :largo="true" buttonText="Ver Factura" />
          </button>
        </div>
      </div>
    </div>
    <div v-for="item in boletos" :key="item.id" :value="[item.attributes]">
      <div
        class="container2"
        v-for="item in item.attributes.boletos"
        :key="item.id"
      >
        <div class="fecha">
          <Subtitulo3 v-bind:subTitulo3="convertirFecha(item.fecha_salida)" />
        </div>

        <div class="viaje">
          <Titulo
            v-bind:Titulo="item.subcorrida.tramo.terminal_origen.nombre"
          />
        </div>
        <div class="viajeVuelta">
          <Titulo
            v-bind:Titulo="item.subcorrida.tramo.terminal_destino.nombre"
          />
        </div>

        <div class="hora">
          <Subtitulo3
            v-bind:subTitulo3="
              item.subcorrida.tramo.terminal_origen.abreviatura +
              ' ' +
              new Date(item.subcorrida.fecha_salida).toLocaleTimeString(
                'es-ES',
                { hour: '2-digit', minute: '2-digit' }
              ) +
              ' ' +
              (new Date(item.subcorrida.fecha_salida).getHours() < 12
                ? 'AM'
                : 'PM') +
              ' - ' +
              item.subcorrida.tramo.terminal_destino.abreviatura +
              ' ' +
              new Date(item.subcorrida.fecha_llegada).toLocaleTimeString(
                'es-ES',
                { hour: '2-digit', minute: '2-digit' }
              ) +
              ' ' +
              (new Date(item.subcorrida.fecha_llegada).getHours() < 12
                ? 'AM'
                : 'PM')
            "
          />
        </div>
        <div class="pasajero">
          <Subtitulo3 subTitulo3="Pasajeros" />
        </div>
        <v-row>
          <div class="nombre">
            <Titulo v-bind:Titulo="item.nombre_pasajero" />
          </div>
          <div class="precio">
            <Subtitulo3 v-bind:subTitulo3="'$' + item.monto_total_pagar" />
          </div>
          <div class="Facturar">
            <button v-if="validaDiasFacturacion(item.subcorrida.fecha_salida, dias_facturar_despues_salida) === true && item.abordado === 1 && item.facturado === 0" @click="showAddFactura(item)" id="button">
              <App-button :largo="true" buttonText="Facturar" />
            </button>
          </div>
        </v-row>
        <div class="asiento">
          <Subtitulo3 v-if="item.asiento != null"
            v-bind:subTitulo3="
              'Asiento ' +
              item.asiento +
              ' Categoría ' +
              item.categoria_asiento.nombre
            "
          />
          <Subtitulo3 v-else
            v-bind:subTitulo3="
              'En blanco' +
              ' Categoría ' +
              item.categoria_asiento.nombre
            "
          />
        </div>

        <div v-if="item.descuento !== null" class="descuento">
          <Subtitulo3 v-bind:subTitulo3="'Descuento ' + item.descuento.nombre" />
        </div>

        <div v-if="item.facturado === 1"  class="verfactura">
          <button @click="showGetFactura(item)" id="button">
            <App-button :largo="true" buttonText="Ver Factura" />
          </button>
        </div>
      </div>
    </div>
    <v-app>
      <!--MODAL VER FACTURA-->
      <v-dialog v-model="showVerFacturaModal" width="auto">
        <v-card>
          <v-card-text>
            <h1><strong>Información</strong></h1>
            <p v-if="token_access != null">Boleto facturado en {{ fecha_facturado }}</p>
            <p v-else>Boleto facturado en {{ Date.now() }}</p>
            <v-row>
              <div class="imagenpdf" @click="downloadFactura('pdf')">
                <img src="/img/pdf.svg" />
              </div>
              <div class="imagenxml" @click="downloadFactura('xml')">
                <img src="/img/xml.svg" />
              </div>
              <div class="pdf">
                <h1>PDF</h1>
              </div>
              <div class="xml">
                <h1>XML</h1>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="showVerFacturaModal = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--MODAL FACTURAR-->
      <v-dialog v-model="showFacturarModal" width="500px">
        <v-card>
          <v-card-text>
            <h1><strong>Confirmar datos de facturación</strong></h1>
            <br />
            <v-row>
              <v-select
                class="col-12"
                v-model="factura.regimen_fiscal"
                label="Seleccione Régimen Fiscal"
                :items="regimenes"
                item-text="regimen_fiscal"
                item-value="clave"
                return-object
              ></v-select>
              <v-text-field
                class="col-12"
                v-model="factura.rfc"
                label="RFC"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.razon_social"
                label="Razón Social"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.cp"
                label="Código Postal"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.email"
                label="Correo donde enviar factura"
                hide-details="auto"
              ></v-text-field>
            </v-row>
            <br />
            <p class="text-left"><strong>Opcionales:</strong></p>
            <v-row>
              <v-text-field
                class="col-12"
                v-model="factura.email_adicional"
                label="Correo adicional donde enviar factura"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.calle"
                label="Calle"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.numero"
                label="Número"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.colonia"
                label="Colonia"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.estado"
                label="Estado"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                class="col-12"
                v-model="factura.ciudad"
                label="Ciudad"
                hide-details="auto"
              ></v-text-field>
            </v-row>
            <br />
            <v-checkbox
              v-if="this.token_access != null"
              v-model="saveData"
              label="Guardar estos datos de facturación"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="col-6" @click="addFactura()"
              >Aceptar</v-btn
            >
            <v-btn color="primary" class="col-6" @click="closeModal()"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 470px) {
  .container2 {
    margin-top: 15%;
    margin-left: 5%;
    border-radius: 4%;
    width: 14.5em;
    height: 90%;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: -2px 2.5px 2px 2px rgba(208, 215, 230, 255);
  }
  .fecha {
    font-family: "Montserrat";
    font-size: 14px;
    margin-top: -30px;
    margin-left: 2.2%;
    font-weight: 400;
    text-align: left;
  }
  .viaje {
    font-weight: 400;
    font-size: 16px;
    margin-left: 2.2%;
    margin-top: -9%;
    text-align: left;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: 2.2%;
    margin-top: -35px;
    text-align: left;
  }
  .hora {
    font-size: 12px;
    margin-top: -35px;
    margin-left: 2.2%;
    font-weight: bold;
    text-align: left;
  }
  .pasajero {
    font-size: 14px;
    margin-left: -70%;
    margin-top: -5%;
  }
  .nombre {
    font-size: 16px;
    font-weight: bold;
    margin-left: 5%;
    margin-top: -20px;
  }
  .precio {
    font-size: 12px;
    margin-top: -5%;
    margin-left: 10%;
  }
  .Facturar {
    //margin-left: 18.5%;
    //margin-top: -100px;
    margin-top: -50px;
    margin-left: 220px;
  }
  .verfactura {
    margin-top: -50px;
    margin-left: 220px;
  }
  .asiento {
    font-size: 12px;
    font-weight: bold;
    margin-left: -40%;
    margin-top: -7%;
  }
  .nombre2 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 5%;
    margin-top: -2%;
  }
  .asiento2 {
    font-size: 12px;
    font-weight: bold;
    margin-left: -35%;
    margin-top: 3%;
  }
  .precio2 {
    font-size: 12px;
    margin-top: -2%;
    margin-left: -7%;
  }
  .descuento {
    font-size: 12px;
    font-weight: bold;
    margin-left: 2.2%;
    margin-top: -10%;
    text-align: left;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 35%;
    background: #fff;
    width: 320px;
    height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
  }
  .parrafo {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
  }
  .pdf {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 35%;
  }
  .xml {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 10%;
  }
  .imagenpdf {
    margin-top: 4%;
    margin-left: 35%;
    width: 340px;
  }
  .imagenxml {
    margin-left: 55%;
    margin-top: -11%;

    width: 340px;
  }
  .cerrar {
    margin-top: -9%;
    margin-left: -70%;
  }
  hr {
    display: block;
    height: 0px;
    width: 100%;
    border: 0;
    border-top: 2.5px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: 0%;
  }
}
@media screen and (min-width: 471px) and (max-width: 766px) {
  .container2 {
    margin-top: 10%;
    margin-left: 18%;
    border-radius: 7%;
    width: 400px;
    height: 100%;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: -4px 4.5px 4px 4px rgba(208, 215, 230, 255);
  }
  .fecha {
    font-family: "Montserrat";
    font-size: 14px;
    margin-top: -25px;
    margin-left: 7%;
    font-weight: 500;
    text-align: left;
  }
  .viaje {
    font-weight: 400;
    font-size: 16px;
    margin-left: 7%;
    margin-top: -9%;
    text-align: left;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -17%;
    margin-top: -35px;
    text-align: left;
    margin-left: 7%;
  }
  .hora {
    font-size: 14px;
    margin-top: -35px;
    margin-left: 7%;
    font-weight: bold;
    text-align: left;
  }
  .pasajero {
    font-size: 16px;
    margin-left: -63%;
    margin-top: -5%;
  }
  .nombre {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -20px;
  }
  .precio {
    font-size: 12px;
    margin-top: -8%;
    margin-left: 10%;
  }
  .Facturar {
    //margin-left: 17%;
    //margin-top: -100px;
    margin-top: -50px;
    margin-left: 58%;
  }
  .verfactura {
    margin-top: -50px;
    margin-left: 58%;
  }
  .asiento {
    font-size: 12px;
    font-weight: bold;
    margin-left: -38%;
    margin-top: -8%;
  }
  .nombre2 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -2%;
  }
  .asiento2 {
    font-size: 12px;
    font-weight: bold;
    margin-left: -31%;
    margin-top: 4%;
  }
  .precio2 {
    font-size: 12px;
    margin-top: -2%;
    margin-left: -5%;
  }
  .descuento {
    font-size: 12px;
    font-weight: bold;
    margin-left: 7%;
    margin-top: -8%;
    text-align: left;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 45%;
    background: #fff;
    width: 320px;
    height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
  }
  .parrafo {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
  }
  .pdf {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 35%;
  }
  .xml {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 15%;
  }
  .imagenpdf {
    cursor: pointer;
    margin-top: 4%;
    margin-left: 35%;
    width: 340px;
  }
  .imagenxml {
    cursor: pointer;
    margin-left: 55%;
    margin-top: -6%;
    width: 340px;
  }
  .cerrar {
    margin-top: -9%;
    margin-left: -70%;
  }
  hr {
    display: block;
    height: 0px;
    width: 100%;
    border: 0;
    border-top: 2.5px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: 0%;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .container2 {
    margin-top: 10%;
    margin-left: 30%;
    border-radius: 7%;
    width: 400px;
    height: 280px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: -4px 4.5px 4px 4px rgba(208, 215, 230, 255);
  }
  .fecha {
    font-family: "Montserrat";
    font-size: 14px;
    margin-top: -30px;
    font-weight: 500;
    text-align: left;
    margin-left: 6%;
  }
  .viaje {
    font-weight: 400;
    font-size: 16px;
    margin-left: 5.7%;
    margin-top: -9%;
     text-align: left;
  }
  .viajeVuelta {
    font-size: 16px;
     margin-left: 5.7%;
    margin-top: -35px;
    text-align: left;
  }
  .hora {
    font-size: 14px;
    margin-top: -35px;
    margin-left: -33%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 16px;
    margin-left: -63%;
    margin-top: -5%;
  }
  .nombre {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -20px;
  }
  .precio {
    font-size: 12px;
    margin-top: -8%;
    margin-left: 10%;
  }
  .Facturar {
    //margin-left: 22%;
    //margin-top: -12%;
    margin-top: -15%;
    margin-left: 68%;
  }
  .verfactura {
    margin-top: -15%;
    margin-left: 68%;
  }
  .asiento {
    font-size: 12px;
    font-weight: bold;
    margin-left: -38%;
    margin-top: -8%;
  }
  .nombre2 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -2%;
  }
  .asiento2 {
    font-size: 12px;
    font-weight: bold;
    margin-left: -31%;
    margin-top: 4%;
  }
  .precio2 {
    font-size: 12px;
    margin-top: -2%;
    margin-left: -5%;
  }
  .descuento {
    font-size: 12px;
    font-weight: bold;
    margin-left: 6%;
    margin-top: -8%;
    text-align: left;
  }
  
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    width: 320px;
    height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
  }
  .parrafo {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
  }
  .pdf {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 35%;
  }
  .xml {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 15%;
  }
  .imagenpdf {
    cursor: pointer;
    margin-top: 4%;
    margin-left: 35%;
    width: 340px;
  }
  .imagenxml {
    cursor: pointer;
    margin-left: 55%;
    margin-top: -6%;
    width: 340px;
  }
  .cerrar {
    margin-top: -9%;
    margin-left: -70%;
  }
}
@media screen and (min-width: 1201px) {
  .container2 {
    margin-top: 6%;
    margin-left: 30%;
    border-radius: 7%;
    width: 400px;
    height: 280px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: -4px 4.5px 4px 4px rgba(208, 215, 230, 255);
  }
  .fecha {
    font-family: "Montserrat";
    font-size: 14px;
    margin-top: -30px;
    margin-left: 5%;
    font-weight: 500;
    text-align: left;
  }
  .viaje {
    font-weight: 400;
    font-size: 16px;
    margin-left: 5%;
    margin-top: -9%;
    text-align: left;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: 5%;
    margin-top: -35px;
    text-align: left;
  }
  .hora {
    font-size: 14px;
    margin-top: -35px;
    margin-left: -33%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 16px;
    margin-left: -63%;
    margin-top: -5%;
  }
  .nombre {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -20px;
  }
  .precio {
    font-size: 12px;
    margin-top: -8%;
    margin-left: 10%;
  }
  .Facturar {
    //margin-left: 68.4%;
    //margin-top: -22%;
    margin-top: -15%;
    margin-left: 65%;
  }
  .verfactura {
    margin-top: -15%;
    margin-left: 65%;
  }
  .asiento {
    font-size: 12px;
    font-weight: bold;
    margin-left: -38%;
    margin-top: -8%;
  }
  .nombre2 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 9%;
    margin-top: -2%;
  }
  .asiento2 {
    font-size: 12px;
    font-weight: bold;
    margin-left: -31%;
    margin-top: 4%;
  }
  .precio2 {
    font-size: 12px;
    margin-top: -2%;
    margin-left: -5%;
  }
  .descuento {
    font-size: 12px;
    font-weight: bold;
    margin-left: 6%;
    margin-top: -8%;
    text-align: left;

  }
  
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    width: 320px;
    height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
  }
  .parrafo {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
  }
  .pdf {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 35%;
  }
  .xml {
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
    margin-left: 15%;
  }
  .imagenpdf {
    cursor: pointer;
    margin-top: 4%;
    margin-left: 35%;
    width: 340px;
  }
  .imagenxml {
    cursor: pointer;
    margin-left: 55%;
    margin-top: -5.4%;
    width: 340px;
  }
  .cerrar {
    margin-top: -9%;
    margin-left: -70%;
  }
}
</style>

<script>
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import AppButton from "@/components/ButtonMini.vue";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      token_access: null,
      dias_facturar_despues_salida: null,
      saveData: false,
      boletos: [],
      boleto_attributes: [],
      showVerFacturaModal: false,
      boleto_id: null,
      fecha_facturado: null,
      showFacturarModal: false,
      factura: {
        type: "facturación",
        regimen_fiscal: null,
        regimen_fiscal_key: null,
        regimen_fiscal_id: null,
        rfc: null,
        razon_social: null,
        cp: null,
        email: null,
        email_adicional: null,
        calle: null,
        numero: null,
        colonia: null,
        estado: null,
        ciudad: null,
        boleto_id: null,
      },
      regimenes: null,
      cliente: [],
    };
  },
  props:{
    boleto: {required: false}
  },
  components: {
    Titulo,
    Subtitulo3,
    AppButton,
  },
  mounted() {
    this.getRegimenes();
    this.token_access = localStorage.getItem("access_token");
    if (this.token_access != null) {
      this.verBoletos();
    }
  },
  watch: {
    'factura.rfc':{
        handler: "checkRFC",
        immediate: false
    },
  },

  methods: {
    checkRFC(){
        if(this.factura.rfc === null){ return; }; 
        if(this.factura.rfc.length >= 10){
            this.getCliente(this.factura.rfc);
        }else{
            return;
        }
    },
    async getCliente(rfc){
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };

      axios.get(`/facturacion/recuperarClienteNa`, rfc, options).then((response) => {
        this.cliente = response.data.data;
        this.factura.regimen_fiscal = this.regimenes.filter(item => item.id == this.cliente.regimen_fiscal_id);
        this.factura.regimen_fiscal_key = this.factura.regimen_fiscal[0].clave;
        this.factura.regimen_fiscal_id = this.factura.regimen_fiscal[0].id;
        this.factura.razon_social = this.cliente.razon_social;
        this.factura.cp = this.cliente.cp;
        this.factura.email = this.cliente.email;
        this.factura.email_adicional = this.cliente.email_adicional;
        this.factura.calle = this.cliente.calle;
        this.factura.numero = this.cliente.numero;
        this.factura.colonia = this.cliente.colonia;
        this.factura.estado = this.cliente.estado;
        this.factura.ciudad = this.cliente.ciudad;
      });
    },
    validaDiasFacturacion(fecha_salida, dias_facturar_salida){
      var date_1 = new Date(Date.now());
      var date_2 = new Date(fecha_salida);
      var day_as_milliseconds = 86400000;
      var diff_in_millisenconds = (date_1 - date_2);
      var diff_in_days = diff_in_millisenconds / day_as_milliseconds;
      if(diff_in_days < Number(dias_facturar_salida)){
        return true;
      }else{
        return false;
      }
    },
    async saveDataFactura() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };

      axios
        .post(`/facturacion/datosFacturacion`, this.factura, options)
        .then((response) => {
          this.$notify({
            group: "apolo",
            type: "success",
            title: "Notificación Apolo Platinum",
            text: "Datos de facturación guardados satisfactoriamente",
          });
        });
    },

    async addFactura() {
      this.factura.regimen_fiscal_key = this.factura.regimen_fiscal.clave;
      this.factura.regimen_fiscal_id = this.factura.regimen_fiscal.id;
      if (this.saveData) {
        this.saveDataFactura();
      }
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };

      axios
        .post(`/facturacion/crearFacturaNa`, this.factura, options)
        .then((response) => {
          this.$notify({
            group: "apolo",
            type: "success",
            title: "Notificación Apolo Platinum",
            text: "Factura creada satisfactoriamente",
          });
          this.boleto.facturado = 1;
          if(this.token_access != null){
            this.verBoletos();
          }else{
            this.showGetFactura(this.boleto);
          }
          this.closeModal();
        })
        .catch((error) => {
          if(error.response.data.message != undefined){
              this.$notify({
                  group: "apolo",
                  type: "error",
                  title: "Notificación Apolo Platinum",
                  text: error.response.data.message +': '+error.response.data.error_details
              });
          }
          if(error.response.data.errors != undefined){
              this.$notify({
                group: "apolo",
                  type: "error",
                  title: "Notificación Apolo Platinum",
                  text: error.response.data.errors[0].detail
              });
          }
        });
    },

    async getClienteAuth() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };
      axios
        .get(`/facturacion/recuperarClienteAuth`, options)
        .then((response) => {
          this.cliente = response.data.data;
          //console.log(this.cliente);
          this.factura.regimen_fiscal = this.regimenes.filter(
            (item) => item.id == this.cliente.regimen_fiscal_id
          );
          this.factura.regimen_fiscal = this.factura.regimen_fiscal[0];
          this.factura.regimen_fiscal_key = this.factura.regimen_fiscal.clave;
          this.factura.regimen_fiscal_id = this.factura.regimen_fiscal.id;
          this.factura.rfc = this.cliente.rfc;
          this.factura.razon_social = this.cliente.razon_social;
          this.factura.cp = this.cliente.cp;
          this.factura.email = this.cliente.email;
          this.factura.email_adicional = this.cliente.email_adicional;
          this.factura.calle = this.cliente.calle;
          this.factura.numero = this.cliente.numero;
          this.factura.colonia = this.cliente.colonia;
          this.factura.estado = this.cliente.estado;
          this.factura.ciudad = this.cliente.ciudad;
        })
        .catch((error) => {
          //console.log(error.response.data.error)
        });
    },

    closeModal() {
      this.factura.regimen_fiscal = null;
      this.factura.rfc = null;
      this.factura.razon_social = null;
      this.factura.cp = null;
      this.factura.email = null;
      this.factura.email_adicional = null;
      this.factura.calle = null;
      this.factura.numero = null;
      this.factura.colonia = null;
      this.factura.estado = null;
      this.factura.ciudad = null;
      this.factura.boleto_id = null;
      this.showFacturarModal = false;
    },

    showAddFactura(item) {
      this.factura.boleto_id = item.id;
      if(this.token_access != null){
        this.getClienteAuth();
      }
      this.showFacturarModal = true;
    },

    showGetFactura(item) {
      this.boleto_id = item.id;
      this.fecha_facturado = item.fecha_facturado;
      this.showVerFacturaModal = true;
    },

    async downloadFactura(type) {
      const token = localStorage.getItem("access_token");
      const options = {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
        responseType: "blob",
      };

      axios
        .get(
          `/facturacion/descargarFacturaNa?boleto_id=${this.boleto_id}&type=${type}`,
          options
        )
        .then((response) => {
          let fileName;
          if (type === "pdf") {
            fileName = `factura${this.boleto_id}.pdf`;
          }
          if (type === "xml") {
            fileName = `factura${this.boleto_id}.xml`;
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        });
    },

    async getRegimenes() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };

      axios.get(`/facturacion/regimenesFiscalesNa`, options).then((response) => {
        this.regimenes = response.data.data;
      });
    },

    async verBoletos() {
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        },
      };
      axios
        .get("/misBoletos", config)
        .then((response) => {
          this.dias_facturar_despues_salida = response.data.dias_facturar_despues_salida;
          const boletos = response.data.data;
          const boletos_attributes = response.data.data[0].attributes;
          this.boletos_attributes = boletos_attributes;
          this.boletos = boletos;
        })
        .catch((error) => {
          //console.log("Aqui estuvo el error en ver mis boletos");
          console.log(error);
        });
    },

    convertirFecha(fecha) {
      const diasSemana = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const fechaObj = new Date(fecha);
      const diaSemana = diasSemana[fechaObj.getDay()];
      const diaMes = fechaObj.getDate();
      const nombreMes = meses[fechaObj.getMonth()];
      const fechaFormateada = `${diaSemana} ${diaMes} de ${nombreMes}`;

      return fechaFormateada;
    },
  },
};
</script>