<template>
  <div class="container" v-if="boletosUpdate">
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <div class="row" v-for="(boleto, index) in boletos" :key="index + 1">
          <div v-show="boleto.check == false" class="col col-12">
            <h1 class="pasajero">Pasajero {{ index + 1 }} vuelta</h1>
          </div>
          <div class="col col-12">
            <h2 v-show="boleto.check == false" class="asiento">Asiento {{ boleto.asiento }} Categoría {{ boleto.categoria.nombre }}</h2>
          </div>
          <div class="col col-12 nombre" v-show="boleto.check == false">
            <input
              @input="saveToLocalStorage(); validation(index,'nombre',boleto.nombre_pasajero);"
              type="Nombre"
              v-model="boleto.nombre_pasajero"
              placeholder="Nombre"
            />
          </div>

          <div class="col col-12 edad">
            <span style="font-family: Montserrat; font-weight: 500; color: #1a2b4b; font-size: 15px; margin-right: 3px;">Edad</span>
            <input
              @input="saveToLocalStorage(); validation(index,'edad',boleto.edad);"
              type="number"
              v-model="boleto.edad"
              placeholder="45"
            />
          </div>

          <div class="col col-12 descuento" v-if="descuento.length > 0">
            <select
              v-model="boleto.descuento"
              @change="
                validarDescuento(index);
              "
              class="icon-rtl"
            >
              <option value=null disabled selected>Descuento</option>
              <option
                v-for="item in descuento"
                :key="item.id"
                :value="{
                  nombre: item.attributes.nombre,
                  id: item.id,
                  mensaje_correo: item.attributes.mensaje_correo,
                  tipo_descuento: item.attributes.tipo_descuento,
                  valor: item.attributes.valor,
                  tipo_valor: item.attributes.tipo_valor,
                }"
              >
                {{ item.attributes.nombre }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import Back from "@/components/Back.vue";
export default {
  name: "HomeView",

  components: {
    Titulo,
    Subtitulo3,
    Back,
  },
  props: {
    boletos_ida: {required:false},
    boletos_vuelta: {required:false}
  },
  data: () => ({
    boleto: {descuento: null},
    descuento: [],
    selectDescuento: [],
    selectedOption: '',
    descuentoAplicado: true,
    descuentoAnterior: null,
    ocultar: false,
    nombre: "",
    boletos: null,
    boletos_ida_final: null,
    descuentoAplicado: false,
    boletosUpdate: false
  }),
  async created() {
    this.boletos = JSON.parse(this.boletos_vuelta);
    for(let i=0; i < this.boletos.length; i++){
      this.boletos[i].check = false;
    }
    Promise.all([this.obtenerDescuento(), this.validar()]).then(function () {});
    this.boletosUpdate = true;
  },
  mounted: function() {
    this.validaChecks();
  },
  watch: {
    /*
    boletos_ida: {
      handler: 'validaChecks',
      inmediate: false,
      deep: true
    }
      */
  },
  methods: {
    validation(index, type, value){
      switch(type){
        case 'edad':
          if(value.length > 2){
            this.boletos[index].edad = value.substr(0,2);
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: 'Máximo 2 dígitos para la edad de pasajero',
              duration: -1
            });
          }
          if(value <= 0){
            this.boletos[index].edad = '';
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: 'La edad debe ser mayor a 0',
              duration: -1
            });
          }
          break;
        case 'nombre':
          if(value.length > 100){
            this.boletos[index].nombre_pasajero = value.substr(0,100);
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: 'Máximo 100 caracteres para el nombre de pasajero',
              duration: -1
            });
          }
          break;
      }
      this.saveToLocalStorage();
    },
    validaChecks(data){
      //Limpia boletos de vuelta
      /*
      for(let i=0; i < this.boletos.length; i++){
        this.boletos[i].nombre_pasajero = "";
        this.boletos[i].edad = "";
        this.boletos[i].descuento = null;
      }
        */
      //valida que existen datos agregado al check
      if(data != undefined){
        if(data.length > 100){
          this.boletos_ida_final = JSON.parse(data);
        }else{
          this.boletos_ida_final = data;
        }
      }
      //Limpia vista y asigna valores para vuelta
      this.boletosUpdate = false;
      let contador = -1;
      if(this.boletos_ida_final != undefined && this.boletos_ida_final != null){
        for(let i=0; i < this.boletos_ida_final.length; i++){
          if(this.boletos_ida_final[i].check == true){
            contador++;
            if(contador+1 <= this.boletos.length){
              if(this.boletos_ida_final[i].nombre_pasajero != undefined){
                this.boletos[contador].nombre_pasajero = this.boletos_ida_final[i].nombre_pasajero;
              }
              if(this.boletos_ida_final[i].edad !== undefined){
                this.boletos[contador].edad = this.boletos_ida_final[i].edad;
              }
              if(this.boletos_ida_final[i].descuento !== null){
                this.boletos[contador].descuento = this.boletos_ida_final[i].descuento;
              }
            }else{
              this.boletos_ida_final[i].check = false;
              this.$emit('eventBoletosIda', JSON.stringify(this.boletos_ida_final));
              this.$notify({
                group: "apolo",
                type: "error",
                title: "Notificación Apolo Platinum",
                text: "No existen boletos de vuelta para asignar datos, deselecciona y selecciona para auto asignar.",
                duration: -1
              });
            }
          }
        }
      }
      /*
      else{
              this.boletos[i].check = false;
              if(this.boletos_ida_final[i].nombre_pasajero !== undefined){
                this.boletos[i].nombre_pasajero = "";
              }
              if(this.boletos_ida_final[i].edad !== undefined){
                this.boletos[i].edad = "";
              }
              if(this.boletos_ida_final[i].descuento !== null){
                this.boletos[i].descuento = null;
              }
            }
      */
      this.saveToLocalStorage();
      this.boletosUpdate = true;
    },
    async obtenerDescuento() {
      const id = localStorage.getItem('subcorrida_vuelta_id');
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .get(`/descuentos/listActive`+'?subcorrida_id='+id, config)
        .then((response) => {
          this.descuento = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveToLocalStorage() {
      //localStorage.setItem("boleto_vuelta", JSON.stringify(this.boletos));
      this.$emit('eventBoletosVuelta', JSON.stringify(this.boletos));
    },
    
    validar() {
      const value = parseInt(localStorage.getItem("value"));
      if (value === 0) {
        this.ocultar = false;
      } else {
        this.ocultar = true;
      }
    },
    showDescuento() {
      if (this.descuento === null) {
      } else {
      }
    },
    async validarDescuento(index) {
      const montoIda = parseFloat(localStorage.getItem("monto_ida"));
      if (
        this.boletos[index].descuentoAplicado &&
        this.boletos[index].descuento === this.boletos[index].descuentoAnterior
      ) {
        return;
      }
          
      const tipo = this.boletos[index].descuento.tipo_valor;
      const valor = this.boletos[index].descuento.valor;
      const precio = this.boletos[index].precio_lista;
      if (tipo == "%") {
        const Porcentual = valor / 100;
        const descuento = parseFloat((Porcentual * precio).toFixed(2));
        const valorDescuento = parseFloat(precio) - parseFloat(descuento);
        this.boletos[index].monto_descuento = descuento;
        this.boletos[index].monto_total_pagar = valorDescuento;
        this.boletos[index].descuentoAplicado = true;
        const total = this.boletos.reduce((acumulador, boleto) => {
          return acumulador + boleto.monto_total_pagar;
        }, 0);
        localStorage.setItem("monto_ida", total);
      } else {
        const valorDescuento = parseFloat(precio) - parseFloat(valor);
        this.boletos[index].monto_descuento = valor;
        this.boletos[index].monto_total_pagar = valorDescuento
        const total = this.boletos.reduce((acumulador, boleto) => {
          return acumulador + boleto.monto_total_pagar;
        }, 0);
        const  totalVuelta = montoIda + valorDescuento;

        this.boletos[index].descuentoAplicado = true;
      }
      this.saveToLocalStorage();
    },
  },
};
</script>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 420px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #1a2b4b;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .titulo {
    font-family: "Montserrat";
    font-size: 24px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    margin-top: -60px;
    margin-left: 10px;
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
    margin-bottom: 30px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 340px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -175px;
    margin-top: 0px;
  }
  .asiento{
    margin-left: -65px;
  }
  .checkbox {
    margin-left: -80px;
  }
  .nombre{
    margin-left: 10px;
  }
  .edad{
    margin-left: -100px;
  }
  .descuento{
    margin-left: 10px;
  }
}
@media screen and (min-width: 421px) and (max-width: 767px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    padding: 10px;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -175px;
    margin-top: 0px;
  }
  .asiento{
    margin-left: -65px;
  }
  .checkbox {
    margin-left: -80px;
  }
  .nombre{
    margin-left: 10px;
  }
  .edad{
    margin-left: -100px;
  }
  .descuento{
    margin-left: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  .checkb
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -205px;
    margin-top: 0px;
  }
  .asiento{
    margin-left: -105px;
  }
  .checkbox {
    margin-left: -120px;
  }
  .nombre{
    margin-left: -12px;
  }
  .edad{
    margin-left: -122px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1365px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
    margin-top: 2px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -200px;
    margin-top: 50px;
  }
  .asiento{
    margin-left: -125px;
  }
  .checkbox {
    margin-left: -150px;
  }
  .edad{
    margin-left: -132px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -200px;
    margin-top: 50px;
  }
  .asiento{
    margin-left: -125px;
  }
  .checkbox {
    margin-left: -150px;
  }
  .edad{
    margin-left: -132px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    margin-top: 10%;
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
      no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -200px;
    margin-top: 50px;
  }
  .asiento{
    margin-left: -125px;
  }
  .checkbox {
    margin-left: -150px;
  }
  .edad{
    margin-left: -132px;
  }
}
@media screen and (min-width: 1920px){
  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 20px;
  }
  h2 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }
  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    color: #1a2b4b;
    font-size: 15px;
  }

  .clase {
    font-size: 14px;
  }
  #checkbox {
    width: 22px;
    height: 20px;
    border: 1px solid #1e5180;
  }
  input[type="Nombre"] {
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[placeholder] {
    font-size: 20px;
  }
  .columna {
    font-family: "Montserrat";
    font-size: 20px;
  }
  input[type="number"] {
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 70px;
    background-color: rgba(248, 250, 252, 255);
    border: 1px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  select {
    font-family: "Montserrat";
    height: 50px;
    width: 380px;
    font-weight: 400;
    font-size: 18px;
    text-indent: 20px;
    box-shadow: 1px 1px 1px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .icon-rtl {
    background: url("https://cdn-icons-png.flaticon.com/512/5940/5940484.png")
    no-repeat right;
    background-size: 20px;
  }
  .pasajero{
    margin-left: -200px;
    margin-top: 50px;
  }
  .asiento{
    margin-left: -125px;
  }
  .checkbox {
    margin-left: -150px;
  }
  .edad{
    margin-left: -132px;
  }
}
</style>